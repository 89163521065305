import React, { useEffect } from "react"
import "../../../assets/styles/_index.scss"
import Favicon from "react-favicon"
import BcdEmprasaProperty from "../../../components/developers/bcdemprasadeveloper/ourproperties/BcdEmprasaProperty";
import TagManager from 'react-gtm-module'

const favicon_logo = require("../../../images/favicon.png");

const BcdEmprasaPropertyPage = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-J9C48LD' });
  }, []);

  return (
    <>
      <Favicon url={favicon_logo} />

      <BcdEmprasaProperty />
    </>
  )
}

export default BcdEmprasaPropertyPage
